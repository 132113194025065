import animationData from "../Assets/lottiefiles/com.lottiefiles.dotlottie.json";
import { backToAppDashboard } from "./commonFunction";
import closeIconStatic from "../Assets/images/close.svg";
import offersNotApplicableStatic from "../Assets/images/RechargeNotApplicable.svg";
import systemDownStatic from "../Assets/images/lottie.png";

const apiStatus = localStorage.getItem("apiStatus");

const imageUrl = process.env.REACT_APP_IMAGES + "/stw_images/";
const closeIcon = `${imageUrl}close.svg`;
const systemDown = `${imageUrl}STWerrorimage.png`;
const offersNotApplicable = `${imageUrl}RechargeNotApplicable.svg`;

export const initialState = {
  claimedRward: {},
  defaultOptions: {
    loop: true,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  },
  selectedItem: null,
  spinnerValues: [{}, {}, {}, {}, {}, {}],
  rewardCount: null,
  spinData: "",
  flagData: "",
  isCopied: false,
  errorModal: {
    spinWheel: false,
    getFlag: false,
    rewardCount: false,
    requestApp: false,
  },
  selectedData: null,
  rewardId: null,
  postData: {
    benifitId: null,
    rewardType: null,
  },
  disableSpin: false,
};

export const initialModalState = {
  modalName: "",
  title_1: "",
  title_2: "",
  imgSrc: "",
  btnText: "",
  callback: () => {},
};
export const routes = {
  reward_history: "/rewardHistory",
  terms_conditions: "/termsAndConditions",
};

export const modalValues = {
  SystemErrorModal: {
    modalName: "SystemErrorModal",
    title_1: "oops! servers are not responding at the moment",
    imgSrc: apiStatus === "success" ? systemDown : systemDownStatic,
    btnText: "retry",
    callback: () => {},
  },
  InternetDownModal: {
    modalName: "InternetDownModal",
    title_1: "oops! Your internet is down",
    imgSrc: apiStatus === "success" ? systemDown : systemDownStatic,
    btnText: "return home",
    callback: backToAppDashboard,
  },
  OopsModal: {
    modalName: "OopsModal",
    title_1: "oops",
    title_2: "Incorrect answer!",
    title_3: "Better luck next time",
    imgSrc: apiStatus === "success" ? systemDown : systemDownStatic,
    btnText: "return home",
    callback: () => {},
  },
  ServerdownModal: {
    modalName: "ServerdownModal",
    title_1: "looks like our servers are down",
    title_2: "Please retry in sometime",
    title_3: "But you can check reward history if you've won any reward.",
    imgSrc: apiStatus === "success" ? systemDown : systemDownStatic,
    btnText: "close",
    callback: backToAppDashboard,
  },
  OfferApplicableModal: {
    modalName: "OfferApplicableModal",
    title_1: "oops! You have already won reward on your recharge",
    title_2: "You may recharge again after 48 hours to be eligible again",
    imgSrc:
      apiStatus === "success" ? offersNotApplicable : offersNotApplicableStatic,
    btnText: "back home",
    callback: backToAppDashboard,
    isCloseIcon: true,
    closeIcon: apiStatus === "success" ? closeIcon : closeIconStatic,
    link: routes.reward_history,
    linkText: "reward history",
  },
  OfferApplicableUtilityModal: {
    modalName: "OfferApplicableUtilityModal",
    title_1: "oops! You have already won a reward on your utility payment",
    title_2: "You may transact again after 48 hours to be eligible again",
    imgSrc:
      apiStatus === "success" ? offersNotApplicable : offersNotApplicableStatic,
    btnText: "back home",
    callback: backToAppDashboard,
    isCloseIcon: true,
    closeIcon: apiStatus === "success" ? closeIcon : closeIconStatic,
    link: routes.reward_history,
    linkText: "reward history",
  },
  HowToPlayModal: {
    headerText: "how to play",
    modalName: "HowToPlayModal",
    title_1: "oops! You have already won reward on your recharge",
    title_2: "You may recharge again after 48 hours to be eligible again",
    imgSrc:
      apiStatus === "success" ? offersNotApplicable : offersNotApplicableStatic,
    btnText: "back home",
    isCloseIcon: true,
    closeIcon: apiStatus === "success" ? closeIcon : closeIconStatic,
    link: routes.terms_conditions,
    linkText: "terms and conditions",
  },
};

export const modalNames = {
  SERVER_DOWN: "SERVER_DOWN",
};

export const message = "message";
export const claim_reward = "claim_reward";
export const reward_data = "reward_data";
export const msisdn_data = "msisdn_data";
export const circle_id = "circle_id";
export const parent_msisdn = "parent_msisdn";
export const user_name = "user_name";
export const hash_msdin = "hash_msdin";
export const ota_version = "ota_version";
export const selected_profile = "selected_profile";
export const last_recharge_pack = "last_recharge_pack";
export const type = "type";
export const utility_benefit = "utility_benefit";
export const journey_type = "journey_type";
